import axios from "axios";
import AuthService from "../AuthService";

const API_URL = process.env.REACT_APP_API_URL;

const makeAPIRequest = async (options) => {
  const { method, url, data, responseType } = options;
  try {
    const response = await axios({
      method,
      url: API_URL + url,
      data,
      responseType,
      headers: { Authorization: `${localStorage.getItem("token")}` },
    });
    return response.data;
  } catch (error) {
    if (
      error.response?.status === 401 &&
      error.response?.data?.errorCode === "nam_auth_6"
    ) {
      try {
        await AuthService.refreshToken();
        return makeAPIRequest(options);
      } catch (refreshError) {
        throw Error(
          refreshError.response
            ? refreshError.response.data.errorCode
            : refreshError,
        );
      }
    } else {
      throw error.response?.data;
    }
  }
};

export default makeAPIRequest;
