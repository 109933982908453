import { useState, useEffect } from "react";
import { useWorksData } from "./useWorksData";
import { useSearch } from "../context/SearchContext";

export const useSearchAll = () => {
  const {
    works: initialData,
    isLoading: isLoadingWorks,
    error,
  } = useWorksData();

  const { searchAllParam } = useSearch();
  const [searchAllData, setSearchAllData] = useState([]);
  const [isSearching, setIsSearching] = useState(false);

  useEffect(() => {
    setIsSearching(true);
    if (!searchAllParam) {
      setSearchAllData(initialData);
      setIsSearching(false);
      return;
    }
    const escapedSearchTerm = searchAllParam?.replace(
      /[.*+?^${}()|[\]\\]/g,
      "\\$&",
    );
    const regex = new RegExp(`\\b${escapedSearchTerm}\\b`, "i");

    const filterValue = (value) => {
      if (typeof value === "string") {
        return regex.test(value);
      } else if (Array.isArray(value)) {
        return value.some((item) => filterValue(item));
      } else if (typeof value === "object" && value !== null) {
        return Object.values(value).some((item) => filterValue(item));
      }
      return false;
    };

    if (initialData) {
      const filtered = initialData.filter((item) => {
        // Controlla se il termine di ricerca esatto è incluso in uno qualsiasi dei campi dell'elemento
        return Object.values(item).some((value) => filterValue(value));
      });
      // Logica di filtraggio basata su filters
      // Esempio: return item.property === filters.property;
      setSearchAllData(filtered);
      setIsSearching(false);
    }
  }, [initialData, searchAllParam]);

  return {
    searchAllData,
    isLoading: isLoadingWorks || !initialData || isSearching,
    error,
  };
};
