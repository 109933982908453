import React, { useState, useEffect, useRef } from "react";
import SearchItem from "./SearchItem";
import ReactPaginate from "react-paginate";
import { Link, useLocation, useNavigate } from "react-router-dom";
import PrintWorks from "./PrintWorks";
import { useReactToPrint } from "react-to-print";
import { useSearch } from "../context/SearchContext";
import { useFilter } from "../data/useFilter";

export default function SearchList() {
  const { filteredData, error, isLoading: loading } = useFilter();

  const {
    currentPage,
    setCurrentPage,
    scrollPosition,
    setScrollPosition,
    numItemsPerPage,
    setNumItemsPerPage,
  } = useSearch();
  //const itemsPerPage = 10;
  const location = useLocation();
  const listRef = useRef(null);
  const listContainerRef = useRef(null);
  const navigate = useNavigate();
  const indexOfLastItem = (currentPage + 1) * numItemsPerPage;
  const indexOfFirstItem = indexOfLastItem - numItemsPerPage;
  const currentItems = filteredData?.slice(indexOfFirstItem, indexOfLastItem);
  const pageCount = Math.ceil(filteredData?.length / numItemsPerPage);

  const handleChangeNumItems = (event) => {
    setNumItemsPerPage(parseInt(event.target.value));
  };

  const handleClickItem = (itemId) => {
    const scrollTop = listContainerRef.current.scrollTop;
    setScrollPosition(scrollTop);
  };

  useEffect(() => {
    // Ripristina la posizione di scroll quando si torna indietro
    if (listContainerRef.current && !loading && filteredData.length > 0) {
      console.log(scrollPosition);
      listContainerRef.current.scrollTo(0, scrollPosition);
    }
  }, [scrollPosition, loading, filteredData.length]);

  useEffect(() => {
    //const pageParam = new URLSearchParams(location.search).get("page");
    //const page = pageParam ? parseInt(pageParam - 1, 10) : 0;
    navigate({
      // Aggiorna l'URL con il numero di pagina
      search: `?${new URLSearchParams({
        ...Object.fromEntries(new URLSearchParams(location.search)),
        page: currentPage + 1, // Aggiungi 1 perché la pagina inizia da 1
      }).toString()}`,
    });
  }, [currentPage, location.search]);

  const handlePageClick = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
    listRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const handlePrint = useReactToPrint({
    content: () => listRef.current,
  });

  return (
    <>
      <div className="mr-2 flex items-center justify-between px-10 py-4">
        <p className="text-lg">
          Results Found:{" "}
          {loading ? `loading...` : <b>{filteredData?.length}</b>}
        </p>
        <div className="flex items-center gap-2">
          <p>Items per page:</p>
          <select
            className="rounded border p-1"
            value={numItemsPerPage}
            onChange={handleChangeNumItems}
          >
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
          </select>
        </div>
        <div className="flex gap-4">
          <button
            className="relative rounded border border-gray-300 bg-slate-100 p-1 px-8 font-semibold hover:bg-slate-500 hover:text-white focus:outline-none active:bg-slate-700 disabled:bg-slate-300 disabled:text-white disabled:opacity-50"
            onClick={handlePrint}
          >
            Print Preview
          </button>
          <PrintWorks itemsToPrint={currentItems} text={"Print Details"} />
        </div>
      </div>
      <div
        className="overflow-y-scroll px-10 md:h-[calc(100vh-120px)]"
        ref={listContainerRef}
      >
        {!error && (
          <>
            <div className="" ref={listRef}>
              <ul className="">
                {currentItems?.map((item, index) => {
                  const isLast = index === currentItems.length - 1;
                  return (
                    <Link
                      to={`/work/${item.id}`}
                      state={{
                        data: { filteredData },
                        from: `${location.pathname}${location.search}`,
                      }}
                      key={item.id}
                      onClick={() => handleClickItem(item.id)} // Aggiungi l'handler per memorizzare lo scroll
                      // Sposta l'attributo key qui
                    >
                      <SearchItem item={item} index={index} isLast={isLast} />
                    </Link>
                  );
                })}

                {loading &&
                  Array.from({
                    length: numItemsPerPage - currentItems?.length,
                  }).map((_, index) => <PlaceholderItem key={index} />)}
              </ul>
              <div className="print:hidden">
                {pageCount > 1 && (
                  <ReactPaginate
                    previousLabel={"Previous"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    pageCount={pageCount}
                    marginPagesDisplayed={3}
                    pageRangeDisplayed={3}
                    onPageChange={handlePageClick}
                    containerClassName={
                      "pagination flex flex-wrap justify-center items-center text-sm"
                    }
                    previousClassName={
                      "bg-stone-600 text-white px-4 py-2 rounded mr-2"
                    }
                    nextClassName={
                      "bg-stone-600 text-white px-4 py-2 rounded ml-2"
                    }
                    breakClassName={"bg-white px-4 py-2 rounded"}
                    pageClassName={"mx-1"}
                    pageLinkClassName={
                      "bg-white text-neutral-800 px-4 py-2 rounded"
                    }
                    activeLinkClassName={"text-2xl"}
                    disabledClassName={"disabled"}
                    forcePage={currentPage}
                  />
                )}
              </div>
            </div>
          </>
        )}
        {error && <ErrorMessage message={error.message} />}
      </div>
    </>
  );
}

function ErrorMessage({ message }) {
  return <p>{message}</p>;
}

function PlaceholderItem() {
  return (
    <li className="mb-10 flex cursor-pointer items-center justify-around rounded-lg border-transparent bg-white px-10 py-4 shadow-neutral-200 drop-shadow-md transition-all duration-300">
      <div className="mr-4 flex h-60 w-60 flex-shrink-0 animate-pulse items-center justify-center bg-gray-200">
        {/* Immagine fittizia */}
        <div className="h-full w-full animate-pulse rounded bg-gray-300"></div>
      </div>
      {/* Contenuto fittizio */}
      <div className="flex-grow text-center">
        <p className="text-lg font-medium tracking-tight text-neutral-800">
          Cat:
        </p>
        <p className="animate-pulse text-gray-300">Loading...</p>
      </div>
      <div className="flex-shrink-0 flex-grow text-center">
        <p className="text-lg font-medium tracking-tight text-neutral-800">
          Artist:
        </p>
        <p className="animate-pulse text-gray-300">Loading...</p>
      </div>
      <div className="flex-shrink-0 flex-grow text-center">
        <p className="text-lg font-medium tracking-tight text-neutral-800">
          Title:
        </p>
        <p className="animate-pulse text-gray-300">Loading...</p>
      </div>
      <div className="flex-shrink-0 flex-grow text-center">
        <p className="text-lg font-medium tracking-tight text-neutral-800">
          Location:
        </p>
        <p className="animate-pulse text-gray-300">Loading...</p>
      </div>
    </li>
  );
}
