import { useQuery, useQueryClient } from "@tanstack/react-query";
import WorkService from "../service/WorkService";
import { useMemo, useState } from "react";

export function useWorksData() {
  const queryClient = useQueryClient();
  const [isLoadingLocal, setIsLoadingLocal] = useState(false);
  // Funzione per ottenere i dati delle opere dal server
  async function fetchWorks() {
    try {
      const data = await WorkService.getAll();
      return data;
    } catch (error) {
      // Se c'è un errore (es. server non raggiungibile), restituisce i dati dal local storage
      const savedData = localStorage.getItem("worksData");
      if (savedData) {
        return JSON.parse(savedData);
      }
      // Se non ci sono dati nel local storage, restituisce un array vuoto o un valore di fallback
      return [];
    }
  }

  // Funzione per ottenere i dati iniziali dal local storage
  function getInitialData() {
    const savedData = localStorage.getItem("worksData");
    if (savedData) {
      const initialData = JSON.parse(savedData);
      return initialData;
    }
    return undefined;
  }

  const initialData = useMemo(() => getInitialData(), []);

  const {
    data: works,
    isLoading: isQueryLoading,
    error,
  } = useQuery({
    queryKey: ["worksData"],
    queryFn: fetchWorks,
    refetchOnWindowFocus: true,
    refetchOnReconnect: true,

    cacheTime: 1000 * 60 * 60,
    staleTime: 0, // Mantieni i dati in cache per 60 minuti
    refetchInterval: 1000 * 60 * 10, // Aggiorna i dati ogni 10 minuti
    initialData, // Utilizza i dati iniziali dal local storage
    onSuccess: (data) => {
      // Confronta i dati attuali con quelli salvati nel local storage
      // E salva i dati nel local storage solo se sono diversi da quelli salvati
      const savedData = localStorage.getItem("worksData");
      const parsedData = savedData ? JSON.parse(savedData) : null;

      if (JSON.stringify(parsedData) !== JSON.stringify(data)) {
        localStorage.setItem("worksData", JSON.stringify(data));
        queryClient.setQueryData(["worksData"], data);
      }
    },
    onError: (err) => {
      console.error("Fetching data failed:", err.message || "Network Error");
    },
  });

  // Funzione per estrarre tutti i valori "location" dai dati
  const extractLocations = (data) => {
    if (!data) return [];
    return [
      ...new Set(data?.map((item) => item.location?.toUpperCase())),
    ].sort();
  };

  // Funzione per estrarre tutti i valori "catalogue" dai dati
  const extractCatalogues = (data) => {
    return data?.map((item) => Number(item.catalogue));
  };

  // Estrai i valori "location" dai dati ottenuti
  const locations = extractLocations(works);

  // Estrai i valori "catalogue" dai dati ottenuti
  const catalogues = extractCatalogues(works);

  return {
    works: works || [],
    locations,
    catalogues,
    isLoading: isQueryLoading || !works || works.length === 0,
    error,
  };
}
