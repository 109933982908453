import React, { useState, useEffect } from "react";
import Dropdown from "./Dropdown";
import { useSearch } from "../context/SearchContext";
import { useNavigate } from "react-router-dom";
import { useWorksData } from "../data/useWorksData";
import { useArtists } from "../data/useArtists";
import { useExhibitions } from "../data/useExhibitions";
import { useSchools } from "../data/useSchools";

export default function SearchPanel() {
  const {
    filters,
    searchAllParam,
    onFilter,
    onResetFilter,
    onSearchAll,
    setCurrentPage,
  } = useSearch();

  const { locations, catalogues, isLoading } = useWorksData();
  const { artists } = useArtists();
  const { exhibitions } = useExhibitions();
  const { schools } = useSchools();

  const navigate = useNavigate();
  const [localFilters, setLocalFilters] = useState(filters);
  const [searchAllLocalParam, setSearchAllLocalParam] =
    useState(searchAllParam);

  useEffect(() => {
    // Aggiorna i filtri locali quando i filtri nel contesto cambiano
    setLocalFilters(filters);
    setSearchAllLocalParam(searchAllParam);
  }, [filters, searchAllParam]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setLocalFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const handleSearchAllChange = (e) => {
    const { value } = e.target;
    setSearchAllLocalParam(value);
  };

  const handleNumberChange = (e) => {
    const newValue = e.target.value;
    // Verifica se il valore inserito è un numero valido prima di impostarlo
    if (!isNaN(newValue) || newValue === "" || newValue < 0) {
      handleChange(e);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // cerca con i filtri search all
    onSearchAll(searchAllLocalParam);
    // filtra con i filtri di dettaglio
    onFilter(localFilters);

    setCurrentPage(0);
  };

  const handleReset = (e) => {
    e.preventDefault();
    onResetFilter();
    setSearchAllLocalParam("");
    setLocalFilters({ ...filters });
    navigate({
      pathname: window.location.pathname,
    });
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();

      e.target.blur(); // Rimuove il focus dall'input corrente
    }
  };
  return (
    <form onSubmit={handleSubmit} className="flex w-full flex-col gap-8">
      <div className="relative flex w-full items-center justify-between gap-4">
        <Dropdown
          getData={() => (!isLoading && catalogues?.length ? catalogues : [])}
          label="Catalogue:"
          name="catalogue"
          value={localFilters.catalogue}
          onChange={handleNumberChange}
          defaultValue="Select a Number:"
        ></Dropdown>
      </div>
      <div className="relative flex w-full items-center justify-between gap-4">
        <Dropdown
          getData={() => (artists?.length ? artists : [])}
          label="Artist:"
          name="artist"
          value={localFilters.artist}
          onChange={handleChange}
          defaultValue="Select an Artist:"
        ></Dropdown>
      </div>
      <div className="flex w-full items-center justify-between gap-4">
        <label className="w-1/2">Title:</label>
        <input
          className="w-1/2 rounded border border-gray-300 p-1"
          type="text"
          id="title"
          name="title"
          value={localFilters.title}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
        />
      </div>
      <div className="relative flex w-full items-center justify-between gap-4">
        <Dropdown
          getData={() => (schools?.length ? schools : [])}
          label="School:"
          name="school"
          value={localFilters.school}
          onChange={handleChange}
          defaultValue="Select a school:"
        ></Dropdown>
      </div>
      <div className="relative flex w-full items-center justify-between gap-4">
        <Dropdown
          getData={() => (!isLoading && locations?.length ? locations : [])}
          label="Location:"
          name="location"
          value={localFilters.location}
          onChange={handleChange}
          defaultValue="Select a location:"
        ></Dropdown>
      </div>

      <div className="relative flex w-full items-center justify-between gap-4">
        <Dropdown
          getData={() => (exhibitions?.length ? exhibitions : [])}
          label="Exhibitions:"
          name="exhibitions"
          value={localFilters.exhibitions}
          onChange={handleChange}
          defaultValue="Select an exhibition:"
        ></Dropdown>
      </div>
      <div className="flex w-full items-center justify-between gap-4">
        <label className="w-1/2">Destination:</label>
        <input
          type="text"
          id="destination"
          name="destination"
          value={localFilters.destination}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          className="w-1/2 rounded border border-gray-300 p-1"
        />
      </div>
      <div className="flex w-full items-center justify-between gap-4">
        <label className="w-1/2">Search All:</label>
        <input
          type="text"
          id="searchAll"
          name="searchAll"
          autoComplete="off"
          value={searchAllLocalParam}
          onChange={handleSearchAllChange}
          onKeyDown={handleKeyDown}
          className="w-1/2 rounded border border-gray-300 p-1"
        />
      </div>
      <div className="flex w-full items-center justify-between gap-4">
        <label className="w-1/2">From n°:</label>
        <input
          type="number"
          id="from"
          name="from"
          value={localFilters.from}
          onChange={handleNumberChange}
          onKeyDown={handleKeyDown}
          className="w-1/2 rounded border border-gray-300 p-1"
        />
      </div>
      <div className="flex w-full items-center justify-between gap-4">
        <label className="w-1/2">To n°:</label>
        <input
          type="number"
          id="to"
          name="to"
          value={localFilters.to}
          onChange={handleNumberChange}
          onKeyDown={handleKeyDown}
          className="w-1/2 rounded border border-gray-300 p-1"
        />
      </div>
      <div className="flex gap-4">
        <button
          className="w-1/2 rounded border border-gray-300 bg-slate-100 p-1 font-semibold hover:bg-slate-500 hover:text-white focus:outline-none active:bg-slate-700"
          onClick={handleReset}
        >
          Reset
        </button>
        <button
          className="w-1/2 rounded border border-gray-300 bg-slate-100 p-1 font-semibold hover:bg-slate-500 hover:text-white focus:outline-none active:bg-slate-700"
          type="submit"
        >
          Search
        </button>
      </div>
    </form>
  );
}
